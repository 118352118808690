import { useGetCommonTranslations, useUserCountry } from "api"

import TiktokSvg from "public/images/icons/tiktok.svg"
import TwitterSvg from "public/images/icons/twitter.svg"
import InstagramSvg from "public/images/icons/instagram.svg"
import EmailSvg from "public/images/icons/email.svg"
import { useRouter } from "next/router"
import { DEFAULT_LOCALE } from "lib"

interface ContactsProps {
  linkClasses?: string
}

export function Contacts(props: ContactsProps) {
  const { linkClasses } = props
  const { data: translations } = useGetCommonTranslations()
  const { data: country } = useUserCountry()
  const router = useRouter()
  const isRuVersion = (router.locale || DEFAULT_LOCALE) === "ru" || country === "RU"

  const contacts = isRuVersion
    ? [
      {
        Icon: TiktokSvg,
        href: translations?.urls?.tiktok,
      },
      {
        Icon: EmailSvg,
        href: translations?.urls?.email,
      },
    ]
    : [
        {
          Icon: InstagramSvg,
          href: translations?.urls?.instagram,
        },
        {
          Icon: TiktokSvg,
          href: translations?.urls?.tiktok,
        },
        {
          Icon: TwitterSvg,
          href: translations?.urls?.twitter,
        },
        {
          Icon: EmailSvg,
          href: translations?.urls?.email,
        },
      ]

  return (
    <div className="flex space-x-4">
      {contacts.map(({ Icon, href }, index) => (
        <a
          key={index}
          className={linkClasses}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          <Icon className="h-8 w-8" />
        </a>
      ))}
    </div>
  )
}
