import { useContext } from "react"
import { twMerge } from "tailwind-merge"
import {
  Contacts,
  ContentTemplate,
  LanguageSelect,
  StoresButtons,
} from "components"
import { LocaleContext, ThemeContext } from "lib"
import { useGetCommonTranslations } from "api"

import FooterBorder from "public/images/footer-border.svg"
import { StoreButton } from "components/molecules/store-link/store-link"
import styles from "./footer.module.scss"
import cn from "classnames"

const links = ["rules", "safety", "terms", "cookiePolicy", "privacy", "faq", "contactUs"]

export function Footer() {
  const data = useContext(LocaleContext)
  const { data: translations } = useGetCommonTranslations()

  const Copyright = ({ className }: { className: string }) => {
    const currentYear = new Date().getFullYear()
    const text = translations?.locales["footer.copyright"]?.replace(
      "%year%",
      currentYear.toString(),
    )

    return (
      <div
        className={twMerge("text-center font-serif text-sm italic", className)}
      >
        {text}
      </div>
    )
  }

  return (
    <ThemeContext.Provider value="dark">
      <FooterBorder className="h-2 w-full text-black lg:h-3.5" />
      <footer className="bg-black">
        <ContentTemplate className="pt-14 pb-10 lg:flex lg:justify-between lg:pt-16 lg:pb-20">
          <div className="md:flex md:justify-between lg:flex-col lg:mr-6">
            <div className="mb-10 md:mb-0 lg:mb-10">
              <div className="mb-3 text-center font-serif text-xl leading-[30px] md:text-left">
                {translations?.locales["footer.downloadApp"]}
              </div>
              <div className="flex flex-row flex-wrap md:flex-nowrap justify-center items-center gap-3 md:justify-start">
                <StoresButtons
                  urls={data?.app_urls}
                  Component={(props) => (
                    <StoreButton
                      className="h-10"
                      {...props}
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex justify-between md:flex-col md:items-end lg:flex-row lg:justify-between">
              <div className="-ml-3 md:ml-0 md:-mr-3 lg:hidden">
                <LanguageSelect placement="bottom" />
              </div>
              <Contacts linkClasses="transition-colors hover:text-darkgold-300" />
              <Copyright className="hidden lg:ml-4 lg:block" />
            </div>
          </div>

          <div className="lg:flex lg:items-start">
            <div
              className={cn(styles.footerNavMenu,
                "my-10 border-y border-gray-250 py-8 lg:my-0 lg:border-none lg:py-0 grid grid-flow-col auto-cols-min justify-between py-8 lg:py-0 my-10 lg:my-0 lg:ml-4 lg:justify-end gap-y-5 gap-x-2 md:gap-x-12 lg:gap-x-16"
              )}
            >
              {links.map((key, index) => (
                <a
                  key={key}
                  className="text-base text-left transition-colors hover:text-darkgold-300 active:text-darkgold-300 md:text-lg sm:min-w-[144px] md:min-w-[162px] lg:min-w-0"
                  href={translations?.urls && translations?.urls[key]}
                >
                  {translations?.locales[`footer.${key}`]}
                </a>
              ))}
              <a
                className="text-base text-left transition-colors hover:text-darkgold-300 active:text-darkgold-300 md:text-lg sm:min-w-[144px] md:min-w-[162px] lg:min-w-0"
                href={translations?.locales["footer.consumerHealth.link"]}
              >
                {translations?.locales["footer.consumerHealth"]}
              </a>
            </div>

            <Copyright className="lg:hidden" />
            <div className="hidden lg:ml-16 lg:-mt-2.5 lg:block">
              <LanguageSelect placement="bottom" />
            </div>
          </div>
        </ContentTemplate>
      </footer>
    </ThemeContext.Provider>
  )
}
